
import { Chart, Tooltip, registerables } from "chart.js";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";
import gradient from "chartjs-plugin-gradient";
import dayjs from "dayjs";
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";

@Component({})
export default class PriceTrendChart extends Vue {
  @Prop() chartLabels: Array<string | number>;
  @Prop() chartData: Array<Array<{ x: number; y: number }>>;
  @Prop() intersectionLabel: string;

  createChart: any = {};

  get _chartLabels() {
    return this.chartLabels;
  }

  get _chartData() {
    return this.chartData;
  }

  mounted() {
    Chart.register(...registerables);
    Chart.register(gradient);
    this.registerCrossHairPlugin();
    this.initRenderChart();
    //@ts-ignore
    Tooltip.positioners.custom = function (elements, eventPosition) {
      const tooltip = this;
      return {
        x: eventPosition.x,
        y: eventPosition.y,
      };
    };
  }

  @Watch("chartData")
  onMonthsUpdated() {
    this.clearChartData();
  }

  clearChartData() {
    this.$data._chart.update();
    this.initRenderChart();
  }

  firstYear = new Date().getFullYear();
  // CustomTooltip block

  get jahresvergleichTooltipHtmlElements() {
    return [
      document.getElementById("tooltip-p1"),
      document.getElementById("tooltip-p2"),
      document.getElementById("tooltip-p3"),
      document.getElementById("tooltip-p4"),
    ];
  }
  get jahresvergleichTooltipColor() {
    return [
      document.getElementById("tooltip-c0"),
      document.getElementById("tooltip-c1"),
      document.getElementById("tooltip-c2"),
      document.getElementById("tooltip-c3"),
    ];
  }

  getTooltip = (chart) => {
    let tooltip = document.getElementById("my-tooltip");
    let tooltip_span = document.getElementById("tooltip-span");
    let xAlignLeft = window.innerWidth >= 680 ? 45 : 10;
    tooltip_span.innerHTML = chart.tooltip.title;
    if (chart.tooltip.x != undefined) {
      this.jahresvergleichTooltipHtmlElements.forEach((ele, i) => {
        ele.innerHTML =
          chart.tooltip.dataPoints[i] === undefined
            ? null
            : `${chart.tooltip.dataPoints[i].dataset.label}: ${chart.tooltip.dataPoints[i].formattedValue} €`;
      });
    }

    tooltip.style.top = chart.tooltip.y + 35 + "px";

    tooltip.style.left =
      chart.tooltip.x +
      ((chart.tooltip.xAlign === "left" && chart.tooltip.yAlign === "bottom") ||
      (chart.tooltip.xAlign === "left" && chart.tooltip.yAlign === "top")
        ? xAlignLeft + 20
        : chart.tooltip.xAlign === "left"
        ? xAlignLeft
        : chart.tooltip.xAlign === "center"
        ? -95
        : -45) +
      "px";

    return tooltip;
  };

  // 1 trigger
  externalTooltipHandler = (context) => {
    const { chart, tooltip } = context;

    if (context.tooltip.x != undefined) {
      this.jahresvergleichTooltipColor.forEach((ele, i) => {
        ele.style.backgroundColor =
          context.tooltip.labelColors[i] === undefined
            ? "transparent"
            : context.tooltip.labelColors[i].borderColor;
      });
    }

    const tooltipEL = this.getTooltip(chart);

    if (tooltip.opacity === 0 || tooltip.body[0].lines[0] === undefined) {
      tooltipEL.style.opacity = "0";
      tooltipEL.style.display = "none";
    } else {
      tooltipEL.style.opacity = "1";
      tooltipEL.style.display = "block";
    }
  };

  chartjsOptions = {
    type: "line",
    data: {
      labels: this._chartLabels,
      datasets: [
        {
          label: String(this.firstYear),
          data: this._chartData[0],
          borderColor: "#000",
          backgroundColor: "#000",
          borderWidth: 2,
          pointBorderColor: "#000",
          pointBackgroundColor: "#000",
          pointRadius: 0,
          steppedLine: false,
          fill: true,
          gradient: {
            backgroundColor: {
              axis: "y",
              colors: {
                0: "rgba(142, 142,142, 0.0)",
                100: "rgba(142, 142,142, 0.1)",
              },
            },
          },
        },
        {
          label: String(this.firstYear - 1),
          data: this._chartData[1],
          borderColor: "rgba( 40, 127, 184, 1 )",
          backgroundColor: "rgba( 40, 127, 184, 0 )",
          borderWidth: 2,
          pointBorderColor: "rgba( 40, 127, 184, 1 )",
          pointBackgroundColor: "rgba( 40, 127, 184, 1 )",
          pointRadius: 0,
          steppedLine: false,
        },
        {
          label: String(this.firstYear - 2),
          data: this._chartData[2],
          borderColor: "rgba( 231, 126, 35, 1 )",
          backgroundColor: "rgba( 231, 126, 35, 0 )",
          borderWidth: 2,
          pointBorderColor: "rgba( 231, 126, 35, 1 )",
          pointBackgroundColor: "rgba( 231, 126, 35, 1 )",
          pointRadius: 0,
          steppedLine: false,
        },
        {
          label: String(this.firstYear - 3),
          data: this._chartData[3],
          borderColor: "rgba( 141, 67, 173, 1 )",
          backgroundColor: "rgba( 141, 67, 173, 0 )",
          borderWidth: 2,
          pointBorderColor: "rgba( 141, 67, 173, 1 )",
          pointBackgroundColor: "rgba( 141, 67, 173, 1 )",
          pointRadius: 0,
          steppedLine: false,
        },
      ],
    },
    options: {
      aspectRatio: (context) => (window.innerWidth < 700 ? 1 | 0.2 : 0.5 | 2),
      responsive: true,
      lineTension: 1,
      spanGaps: true,
      title: {
        display: false,
        text: "Heizölpreis-Entwicklung in Deutschland",
        fontSize: 22,
        position: "bottom" as "bottom",
      },

      hover: {
        mode: "index",
        intersect: false,
      },
      scales: {
        y: {
          position: "right",
          ticks: {
            beginAtZero: false,
            maxTicksLimit: 7,
            stepSize: 5,
            fontSize: 12,
            padding: 0,
            fontColor: "#1f2023",
            callback(value, index, values) {
              return `${value} €`;
            },
          },
          scaleLabel: {
            display: false,
            labelString: "€ / 100 L",
            fontColor: "#000000",
            fontSize: 12,
          },
          grid: {
            color: "rgba( 211, 211, 219, 0.4 )",
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: true,
            tickMarkLength: 10,
          },
        },

        x: {
          type: "category",
          distribution: "linear" as "linear",
          ticks: {
            autoSkip: true,
            maxTicksLimit: (context) => (window.innerWidth < 500 ? 3 : 12),
            maxRotation: 0,
            fontSize: 12,
            fontColor: "#1f2023",
            padding: 0,
            align: "center",

            callback: (context) => {
              const splittedDate = this.chartLabels[context]
                .toString()
                .split(".");
              return dayjs(
                `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`,
              ).format("MMM");
            },
          },
          scaleLabel: {
            display: false,
            labelString: "",
            fontColor: "#000000",
            fontSize: 12,
          },
          grid: {
            color: "rgba( 211, 211, 219, 0.4 )",
            zeroLineColor: "#cbd0d2",
            zeroLineBorderDash: [3, 9],
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
            tickMarkLength: 10,
          },
        },
      },

      plugins: {
        legend: {
          display: true,
          position: "top" as "top",
          align: "center" as "center",
          labels: {
            fontColor: "rgba(0,0,0)",
            usePointStyle: true,
            pointStyle: "line",
            boxWidth: 50,
            padding: 20,
            font: {
              size: 12,
            },
          },
        },
        tooltip: {
          enabled: false,
          external: this.externalTooltipHandler,
          mode: "index",
          intersect: false,
          position: "custom",
          displayColors: true,
          xPadding: 20,
          yPadding: 20,
          caretPadding: 5,
          cornerRadius: 5,
          borderWidth: 1,
          usePointStyle: true,
          titleColor: "rgba(0, 0, 0, 0.8)",
          backgroundColor: "#fff",
          bodyColor: "rgba(0, 0, 0, 0.8)",
          bodySpacing: 5,
          borderColor: "#000",
          callbacks: {
            label: function (tooltipItem, data) {
              return `${tooltipItem.dataset.label}:${tooltipItem.formattedValue} €`;
            },
          },
        },
        crosshair: {
          sync: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
          line: {
            color: "hsla( 10, 1%, 1%, 1 )",
            width: 1,
            dashPattern: [10, 3],
          },
        },
      },
    },
  };

  initRenderChart() {
    const ctx = document.getElementById("jahresvergleich") as HTMLCanvasElement;

    this.createChart = new Chart(ctx, {
      type: this.chartjsOptions.type as any,
      data: this.chartjsOptions.data,
      options: this.chartjsOptions.options,
    });
  }

  registerCrossHairPlugin() {
    Chart.register(CrosshairPlugin);
  }
}
