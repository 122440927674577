import { render, staticRenderFns } from "./PriceTrendVote.vue?vue&type=template&id=832f7c42&scoped=true"
import script from "./PriceTrendVote.vue?vue&type=script&lang=ts"
export * from "./PriceTrendVote.vue?vue&type=script&lang=ts"
import style0 from "./PriceTrendVote.vue?vue&type=style&index=0&id=832f7c42&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "832f7c42",
  null
  
)

export default component.exports