var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"price-trend-chart-organism"},[_c('LightBox',{attrs:{"title":" Experten-Trend","visibility-state":_vm.showModal},on:{"emit-false-visibility-state":function($event){_vm.showModal = $event}}},[_c('EsyParagraph',[_vm._v("\n      Der Experten-Trend von esyoil zeigt den Durchschnittspreis für Heizöl in\n      Deutschland und folgt der Logik an den Börsen. Dort werden starke\n      Bewegungen als Trends erkannt und markiert. Häufig gibt es eine obere\n      und eine untere Linie des sog. Trendkanals. Weil die Börsenpsychologie\n      dem Trend eine Wirkung auf die Marktteilnehmer zuspricht, besteht eine\n      gewisse Wahrscheinlichkeit, dass sich der Heizölpreis tatsächlich\n      innerhalb des Kanals bewegt. Durchschneidet er aber eine der\n      Außenlinien, gilt der Trend als beendet und eine neue Richtung wird\n      eingeschlagen. Der Trend von esyoil wird von dem Geschäftsführer\n      Clausbruch gesetzt.\n    ")])],1),_vm._v(" "),_c('nav',[_c('ul',[_c('ListItem',{class:`asset-item is--selected-${_vm.checkForSelectedItemString(
          'prognosis',
        )}`,attrs:{"variant":"small"},nativeOn:{"click":function($event){return _vm.updatedChartVariant('prognosis')}}},[_vm._v("\n        Heizölpreis-Tendenz\n      ")]),_vm._v(" "),_c('ListItem',{class:`asset-item is--selected-${_vm.checkForSelectedItemString(
          'history',
        )}`,attrs:{"variant":"small"},nativeOn:{"click":function($event){return _vm.updatedChartVariant('history')}}},[_vm._v("\n        Jahresvergleich\n      ")])],1),_vm._v(" "),_c('EsySelect',{directives:[{name:"show",rawName:"v-show",value:(_vm.chartVariant === 'prognosis'),expression:"chartVariant === 'prognosis'"}],attrs:{"id":"oil-chart-time-range-selection","label":"Zeitraum","variant":"filled","options":_vm.timeRangeOptions,"selected":_vm.monthsQuantity,"disabled":false},on:{"emit-selected-value":function($event){return _vm.changeMonthsQuantity($event)}}})],1),_vm._v(" "),_c('div',{staticClass:"chart-container"},[(_vm.chartVariant === 'prognosis')?_c('PriceChart',{key:_vm.monthsQuantity,class:`oil-prices-chart is--visible-${
        _vm.chartVariant === 'prognosis' && _vm.assets.data.length > 0
      }`,attrs:{"y-achses-label":_vm.assets.reletadUnits,"chart-data-set":_vm.assets.data,"months-quantity":_vm.monthsQuantity,"canvas-i-d":"oil-prices-chart"},on:{"emit-time-period-change":function($event){_vm.chartTimePeriod = $event}}}):_vm._e(),_vm._v(" "),(_vm.chartVariant === 'history')?_c('HistoryChart',{class:`history-prices-chart is--visible-${
        _vm.chartVariant === 'history' && _vm.historyChartData.data.length > 0
      }`,attrs:{"chart-labels":_vm.historyChartData.labels,"chart-data":_vm.historyChartData.data,"height":217}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"question-circle-container"},[_c('Icon',{attrs:{"icon":"question-circle-solid.svg"},nativeOn:{"click":function($event){_vm.showModal = true}}})],1),_vm._v(" "),(
        (_vm.chartVariant === 'prognosis' && _vm.assets.data.length === 0) ||
        (_vm.chartVariant === 'history' && _vm.historyChartData.data.length === 0)
      )?_c('LoadingSpinner',{staticClass:"loading-spinner",attrs:{"timelimit-miliseconds":10000,"loading-failed-message":"Beim laden der Daten ist etwas schief gelaufen, bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal."}}):_vm._e()],1),_vm._v(" "),(_vm.chartVariant === 'prognosis')?_c('EsyFigcaption',[_vm._v("\n    Durchschnittspreise für Deutschland pro 100 Liter inkl. MwSt. bei 3.000\n    Liter Abnahme.\n  ")]):_vm._e(),_vm._v(" "),(_vm.chartVariant === 'history')?_c('EsyFigcaption',[_vm._v("\n    Heizölpreis-Entwicklung der letzten Jahre: Durchschnittspreise pro 100\n    Liter inkl. MwSt. bei 3.000 Liter Abnahme.\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }