

import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({})
export default class SingleGasstationPriceList extends Vue {
  @Prop() data: {type: string, price: number, lastPrice: number, relativeChange: number};

  date = new Date();

}

