import dayjs from "dayjs";

export function calculateAverage(amount, unit, priceData, labels) {
  const now = dayjs();
  const filteredPrices = priceData.filter((_, index) => {
    const date = dayjs(labels[index], "DD.MM.YYYY");
    return date.isAfter(now.subtract(amount, unit));
  });

  const sum = filteredPrices.reduce((acc, price) => acc + price, 0);
  return filteredPrices.length ? sum / filteredPrices.length : 0;
}

export function getPriceComparisonText(currentPrice, averagePrice) {
  return currentPrice > averagePrice
    ? "über dem laufenden"
    : "unter dem laufenden";
}
