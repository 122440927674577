

import { Component, Vue, Prop } from 'nuxt-property-decorator';
import RouterLink from '~/components/atoms/text/RouterLink.vue';

@Component({
  components: {
    RouterLink,
  }
})
export default class StartPageBannerMolecule extends Vue {
  @Prop() image: string;
  @Prop() fallbackImage: string;
  @Prop({ required: true }) title: string;
  @Prop() subTitle: string;
  @Prop({ required: true }) text: string;
  @Prop() href: string;
  @Prop({ default: false }) externalLink: boolean;
}

