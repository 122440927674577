
// Libraries
import { Component, Vue, Prop } from "nuxt-property-decorator";
import dayjs from "dayjs";

// Components
import SingleRating from "~/components/molecules/display/ratings/SingleRating.vue";
import AuthorImage from "~/components/molecules/embedding/AuthorImage.vue";

@Component({
  components: {
    SingleRating,
    AuthorImage,
  },
})
export default class UserCommentMolecule extends Vue {
  @Prop() partnerLogoUrl: string;
  @Prop() title: string;
  @Prop() subTitle: string;
  @Prop() userRatingData: {
    customerStatement: string;
    updatedAtByCustomer: string;
    rating: string;
    partnerStatement: string;
    esyoilStatement: string;
  };

  dayjs = dayjs;
}
