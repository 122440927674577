
// Libraries
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Component, namespace, Prop, Vue } from "nuxt-property-decorator";
dayjs.extend(utc);

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import DataTileItem from "~/components/molecules/display/DataTileItem.vue";
import LoadingSpinner from "~/components/molecules/display/LoadingSpinner.vue";
import SummedRating from "~/components/molecules/display/ratings/SummedRating.vue";
import { getAbsoluteChange } from "~/mixins/oilPricemixin";
// Mixins
import {
  getFormattedPercentage,
  returnPriceFormatDE,
} from "~/mixins/formatting";

const EsyoilStore = namespace("esyoil");

@Component({
  components: {
    DataTileItem,
    LoadingSpinner,
    SummedRating,
    RouterLink,
  },
  methods: {
    returnPriceFormatDE,
    getFormattedPercentage,
    getAbsoluteChange,
  },
})
export default class ExtendedDataTileOrganism extends Vue {
  @Prop() headline: string;
  @Prop() description: string;
  @Prop() disclaimer: string;
  @Prop({ default: "kauf-empfehlung" }) jumpToSectionID: string;
  @Prop({ default: "default" }) variant: string;
  @Prop({ default: "default" }) priceLabel: string;
  @Prop({
    type: Object,
    default() {
      return {
        name: "string",
        currentPrice: {
          value: 0,
          changedAtUnix: 0,
        },
        lastPrice: {
          value: "number",
          changedAtUnix: 0,
        },
        relativeChange: 0,
      };
    },
  })
  data: {
    name: string;
    currentPrice: { value: number; changedAtUnix: number };
    lastPrice: { value: number; changedAtUnix: number };
    relativeChange: number;
  };

  @EsyoilStore.Action("fetchEsyoilRatingData")
  fetchEsyoilRatingData: any;

  @EsyoilStore.State("companyRating")
  companyRating: { ratingValue: number; ratingCount: number };

  async fetch() {
    if (!this.companyRating.ratingCount) {
      await this.fetchEsyoilRatingData();
    }
  }

  date = new Date();
  daydate = {
    today: dayjs().format("DD.MM.YYYY"),
    time: dayjs().format("HH:mm"),
  };

  internalTimeout = null;
  jumpToSection() {
    this.$router.push({ path: `/` });
    this.internalTimeout = setTimeout(() => {
      // TODO: possible memory leak
      this.$router.push({ path: `/#${this.jumpToSectionID}` });
    }, 50);
  }

  returnSwarmDirectionIcon(buyingIntesionIndex) {
    if (buyingIntesionIndex === null) return "question-circle-solid.svg";
    if (buyingIntesionIndex === 1) return "meh-solid.svg";
    if (buyingIntesionIndex === 2) return "meh-solid.svg";
    if (buyingIntesionIndex === 3) return "meh-solid.svg";
    if (buyingIntesionIndex === 4) return "smile-solid.svg";
    if (buyingIntesionIndex === 5) return "smile-solid.svg";
  }

  returnPriceSystemDirectionIcon(buyingIntesionIndex) {
    if (buyingIntesionIndex === null) return "question-circle-solid.svg";
    if (buyingIntesionIndex === "down") return "thumbs-down-solid.svg";
    if (buyingIntesionIndex === "up") return "thumbs-up-solid.svg";
  }

  returnTrendDirectionString(trendValue: number) {
    if (trendValue === 0) return "right";
    if (trendValue < 0) return "sinking";
    if (trendValue > 0) return "rising";
  }

  beforeDestroy() {
    clearTimeout(this.internalTimeout);
  }
}
