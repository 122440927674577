
// Libraries
import { Component, namespace, Vue } from "nuxt-property-decorator";
import { Products } from "~/operations/messages";

// Components
import LightBox from "~/components/molecules/display/LightBox.vue";
import LoadingSpinner from "~/components/molecules/display/LoadingSpinner.vue";
import HistoryChart from "~/components/organisms/chart/HistoryChart.vue";
import PriceChart from "~/components/organisms/chart/PriceChart.vue";
import sendGtmEvent from "~/operations/send-gtm-event";

type variant = "prognosis" | "history";

const OilpriceModule = namespace("oilprice");

@Component({
  components: {
    LoadingSpinner,
    PriceChart,
    HistoryChart,
    LightBox,
  },
})
export default class PriceTrendChartOrganism extends Vue {
  Products = Products;

  timeRangeOptions: Array<{ name: string; value: string }> = [
    { name: "3 Monate", value: "3" },
    { name: "6 Monate", value: "6" },
    { name: "1 Jahr", value: "12" },
    { name: "3 Jahre", value: "36" },
    { name: "5 Jahre", value: "60" },
    { name: "10 Jahre", value: "120" },
  ];

  chartVariant: variant =
    this.$route.query?.chart === "history" ? "history" : "prognosis";
  monthsQuantity = 12;
  showModal = false;

  @OilpriceModule.State("historyChartData")
  historyChartData: any;

  @OilpriceModule.State("assets")
  assets: any;

  @OilpriceModule.Action("fetchHistoricalOilPrices")
  fetchHistoricalOilPrices: any;

  mounted() {
    this.fetchHistoricalOilPrices();
  }

  checkForSelectedItemString(chartVariantTarget: variant) {
    if (this.chartVariant === chartVariantTarget) {
      return true;
    }
  }

  updatedChartVariant(chartVariantSelection: variant) {
    this.chartVariant = chartVariantSelection;
  }

  changeMonthsQuantity(monthsQuantity) {
    this.monthsQuantity = monthsQuantity;

    sendGtmEvent(this, {
      category: "Charts",
      label: `chart-time-${monthsQuantity}-months`,
      action: "Wechsel",
    });
  }
}
