
import { Component, Prop, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";

@Component({
  components: {
    RouterLink,
  },
})
export default class RatingsDisplay extends Vue {
  @Prop() ratingValue: string;
  @Prop() ratingLabel: string;

  returnStarIcon(rating: string | number, starIndex: number) {
    if (rating === null || rating === 0) return "star-regular.svg";
    if (rating >= starIndex) return "star-solid.svg";
    return "star-regular.svg";
  }
}
