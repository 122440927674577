
import dayjs from "dayjs";
import { Component, namespace, Vue } from "nuxt-property-decorator";

const EsyoilModule = namespace("esyoil");

@Component({})
export default class PreisanteileHeizoel extends Vue {
  @EsyoilModule.Action("fetchPreisanteile")
  fetchPreisanteile!: any;

  @EsyoilModule.State("preisanteile")
  preisanteile;

  async fetch() {
    await this.fetchPreisanteile();
  }

  get formattedDate() {
    return dayjs(this.preisanteile.dateFrom).format("DD.MM.YYYY");
  }
}
