// Libraries
import dayjs from "dayjs";
import "dayjs/locale/de";
import isoWeek from "dayjs/plugin/isoWeek";
import { calculateAverage, getPriceComparisonText } from "~/utils/priceUtils";
import { returPriceTrendFormatDE, translateStateName } from "./mixins";
dayjs.extend(isoWeek);
dayjs.locale("de");

export const getFormattedTitle = (
  type: string,
  stateOrCountyName: string,
): string => {
  return `Höchststände und Tiefststände der Heizölpreise ${type} ${translateStateName(
    stateOrCountyName,
  )}`;
};

export const getPriceComparisonText7Days = (
  priceData,
  labels,
  currentAveragePrice,
) => {
  const avgSevenDays = calculateAverage(7, "days", priceData, labels);
  return getPriceComparisonText(currentAveragePrice, avgSevenDays);
};

export const getAbsoluteChange = (
  currentPrice: number,
  lastPrice: number,
  returnNumber = false,
) => {
  if (currentPrice == null || lastPrice == null) return "-";
  const PriceChange = currentPrice - lastPrice;
  if (returnNumber === true) return PriceChange;
  return returPriceTrendFormatDE(Number(PriceChange));
};

export const getPriceComparisonTextThreeMonths = (
  priceData,
  labels,
  currentAveragePrice,
) => {
  const avgSevenDays = calculateAverage(3, "months", priceData, labels);
  return getPriceComparisonText(currentAveragePrice, avgSevenDays);
};

const getPriceDifference = (
  currentPrice: number,
  lastPrice: number,
): string => {
  return getAbsoluteChange(currentPrice, lastPrice).toString().slice(1, -1);
};

export const getPriceDifferenceText = (
  currentPrice: number,
  lastPrice: number,
): string => {
  const priceDifference = getPriceDifference(currentPrice, lastPrice);
  return currentPrice > lastPrice
    ? `${priceDifference} Cent mehr als am Vortrag.`
    : `${priceDifference} Cent weniger als am Vortrag.`;
};
