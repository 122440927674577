export const breakpoints = {
  xs: 576,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const statName = {
  "Baden-Württemberg": "BW",
  Bundesrepublik: "BRD",
  Bayern: "BY",
  Brandenburg: "BB",
  Hessen: "HE",
  "Mecklenburg-Vorpommern": "MV",
  Niedersachsen: "NI",
  "Nordrhein-Westfalen": "NW",
  "Rheinland-Pfalz": "RP",
  Saarland: "SL",
  Sachsen: "SN",
  "Sachsen-Anhalt": "ST",
  "Schleswig-Holstein": "SH",
  Thüringen: "TH",
};

export function getMaxTicksXLimit() {
  return window.innerWidth < breakpoints.md ? 2 : 6;
}

export function getMaxTicksYLimit() {
  return window.innerWidth < breakpoints.md ? 7 : 15;
}
export function getMaxTickSizes(monthsQuantity, innerWidth) {
  if (innerWidth > 500) {
    switch (Number(monthsQuantity)) {
      case 3:
        return 3;
      case 6:
        return 6;
      case 12:
        return 12;
      case 36:
        return 3;
      case 60:
        return 5;
      case 120:
        return 10;
      default:
        3;
    }
  } else {
    return 3;
  }
}

export function getFormattedLabel(label: string) {
  const stateTooltipLabel = statName[label];
  if (!stateTooltipLabel) {
    const words = label.split(/[\s-]+/);
    const abbreviation = words.map((word) => word.charAt(0)).join("");
    return abbreviation;
  }
  return stateTooltipLabel;
}
